<template>
  <div class="topbar-item">
    <div
      class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-l px-2" id="kt_quick_user_toggle">
      <span class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1"></span>
      <span
        class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3" v-if="currentUser.name">
        {{ currentUser.full_name }}
      </span>
      <span class="symbol symbol-30">
        <Avatar :avatarData=currentUser.avatar></Avatar>
        <span v-if="false" class="symbol-label font-size-h5 font-weight-bold">
          {{ currentUser.name.charAt(0).toUpperCase() }}
        </span>
      </span>
    </div>

    <div
      id="kt_quick_user"
      ref="kt_quick_user"
      class="offcanvas offcanvas-right p-10">
      <!--begin::Header-->
      <div class="offcanvas-header d-flex align-items-center justify-content-between pb-5">
        <h3 class="font-weight-bold m-0">
          {{ $t("layout.user_panel.title") }}
        </h3>
        <a
          href="#"
          class="btn btn-xs btn-icon btn-primary" id="kt_quick_user_close">
          <i class="ki ki-close icon-xs "></i>
        </a>
      </div>
      <!--end::Header-->

      <!--begin::Content-->
      <perfect-scrollbar class="offcanvas-content pr-5 mr-n5 scroll" style="max-height: 90vh; position: relative;">
        <!--begin::Header-->
        <div class="d-flex align-items-center mt-5">
          <div class="symbol symbol-100 mr-5">
            <Avatar :avatarData=currentUser.avatar></Avatar>   
          </div>
          <div class="d-flex flex-column">
            <strong
              class="font-weight-bold font-size-h5 text-dark-75" v-if="currentUser.position"
            >
              {{ currentUser.full_name }}
            </strong>
            <div class="text-muted mt-1" v-if="currentUser.position">{{ currentUser.position.name }}</div>
            <br />
            <button class="btn btn-danger btn-bold" @click="onLogout">
              {{ $t("button.logout") }}
            </button>
          </div>
        </div>
        <!--end::Header-->
        <div class="separator separator-dashed mt-8 mb-5"></div>
        <!--begin::Nav-->
        <div class="navi navi-spacer-x-0 p-0">
        
          <!--begin::Item-->
          <router-link
            :to="{ name: 'profile_overview'}"
            @click.native="closeOffcanvas"
            class="navi-item"
          >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-success">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      src="/media/svg/icons/General/User.svg"
                    />
                    <!--end::Svg Icon-->
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">{{ $t("layout.user_panel.profile_title") }}</div>
                <div class="text-muted">
                  {{ $t("layout.user_panel.profile_subtitle") }}
                </div>
              </div>
            </div>
          </router-link>
          <!--end:Item-->

          <!--begin::Item-->
          <router-link
            :to="{ name: 'profile_password'}"
            @click.native="closeOffcanvas"
            class="navi-item"
          >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-warning">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      src="/media/svg/icons/General/Lock.svg"
                    />
                    <!--end::Svg Icon-->
                  </span>
                </div>
              </div>
              <div class="navi-text">
               <div class="font-weight-bold">{{ $t("layout.user_panel.password_title") }}</div>
                <div class="text-muted">
                  {{ $t("layout.user_panel.password_subtitle") }}
                </div>
              </div>
            </div>
          </router-link>
          <!--end:Item-->

          <div class="separator separator-dashed my-7"></div>

          <!--begin::Item-->
          <router-link
            to="/admin/messages"
            @click.native="closeOffcanvas"
            class="navi-item"
          >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-warning">
                    <!--begin::Svg Icon-->
                    <inline-svg src="/media/svg/icons/Communication/Mail-opened.svg" />
                    <!--end::Svg Icon-->
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">{{ $t("layout.user_panel.inbox_title") }}</div>
                <div class="text-muted">{{ $t("layout.user_panel.inbox_subtitle") }}</div>
              </div>
            </div>
          </router-link>
          <!--end:Item-->

          <!--begin::Item-->
          <router-link
            to="/admin/calendar"
            @click.native="closeOffcanvas"
            class="navi-item"
          >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-danger">
                    <!--begin::Svg Icon-->
                    <inline-svg src="/media/svg/icons/Layout/Layout-top-panel-4.svg" />
                    <!--end::Svg Icon-->
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">{{ $t("layout.user_panel.calendar_title") }}</div>
                <div class="text-muted">{{ $t("layout.user_panel.calendar_subtitle") }}</div>
              </div>
            </div>
          </router-link>
          <!--end:Item-->

          <!--begin::Item-->
          <router-link
            :to="{ name: 'tasks'}"
            @click.native="closeOffcanvas"
            class="navi-item"
          >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-primary">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      src="/media/svg/icons/Communication/Clipboard-list.svg"
                    />
                    <!--end::Svg Icon-->
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">{{ $t("layout.user_panel.tasks_title") }}</div>
                <div class="text-muted">{{ $t("layout.user_panel.tasks_subtitle") }}</div>
              </div>
            </div>
          </router-link>
          <!--end:Item-->
        </div>
        <!--end::Nav-->

      </perfect-scrollbar>
      <!--end::Content-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<script>
import { mapGetters } from "vuex";
import { LOGOUT } from "@/core/services/store/auth.module";
//import { SET_PERSONAL_INFO } from "@/core/services/store/profile.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import Avatar from "@/view/content/Avatar";

export default {
  name: "KTQuickUser",
  components: {
    Avatar
  },
  data() {
    return {
      list: [
        {
          title: "Another purpose persuade",
          desc: "Due in 2 Days",
          alt: "+28%",
          svg: "media/svg/icons/Home/Library.svg",
          type: "warning"
        },
        {
          title: "Would be to people",
          desc: "Due in 2 Days",
          alt: "+50%",
          svg: "media/svg/icons/Communication/Write.svg",
          type: "success"
        },
        {
          title: "Purpose would be to persuade",
          desc: "Due in 2 Days",
          alt: "-27%",
          svg: "media/svg/icons/Communication/Group-chat.svg",
          type: "danger"
        },
        {
          title: "The best product",
          desc: "Due in 2 Days",
          alt: "+8%",
          svg: "media/svg/icons/General/Attachment2.svg",
          type: "info"
        }
      ]
    };
  },
  mounted() {
    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
  },
  methods: {
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    },
    closeOffcanvas() { 
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    }
  },
  computed: {
    ...mapGetters(["currentUser"]),
  }
};
</script>
