<template>
  <div>
    <!--begin::Header -->
    <div
      class="d-flex flex-column flex-center py-10 bgi-size-cover bgi-no-repeat rounded-top"
      :style="{ backgroundImage: `url(${backgroundImage})` }"
    >
      <h4 class="text-white font-weight-bold">
        {{ $t("layout.quickactions.title") }}
      </h4>
    </div>
    <!--end::Header -->

    <!--begin::Nav -->
    <div class="row row-paddingless">
      <!--begin:Item-->
      <template v-for="(item, i) in list">
        <div class="col-4" v-bind:key="i" @click.prevent="onClickQuickAction">
          <router-link :to="`/${item.href}`" 
            class="d-block py-10 px-5 text-center bg-hover-light border-right border-bottom"
            
          >
            <span class="svg-icon svg-icon-3x svg-icon-primary">
              <!--begin::Svg Icon-->
              <inline-svg :src="item.svg" />
              <!--end::Svg Icon-->
            </span>
            <span
              class="d-block text-dark-75 font-weight-bold font-size-h6 mt-2 mb-1"
            >
              {{ item.title }}
            </span>
            <span class="d-block text-dark-50 font-size-lg">
              {{ item.desc }}
            </span>
          </router-link>
        </div>
      </template>
      <!--end:Item-->
    </div>
    <!--end::Nav -->
  </div>
</template>

<script>
export default {
  
  name: "KTDropdownQuickAction",
  data() {
    return {
      list: [
        {
          title: this.$t("layout.quickactions.users_title"),
          href: "users",
          desc: this.$t("layout.quickactions.users_subtitle"),
          svg: process.env.BASE_URL + "media/svg/icons/Communication/Group.svg"
        },
        {
          title: this.$t("layout.quickactions.role_title"),
          href: "roles",
          desc: this.$t("layout.quickactions.role_subtitle"),
          svg:
            process.env.BASE_URL + "media/svg/icons/Communication/Shield-user.svg"
        },
        {
          title: this.$t("layout.quickactions.position_title"),
          href: "positions",
          desc: this.$t("layout.quickactions.position_subtitle"),
          svg: process.env.BASE_URL + "media/svg/icons/Code/Git4.svg"
        },
        {
          title: this.$t("layout.quickactions.log_title"),
          href: "log",
          desc: this.$t("layout.quickactions.log_subtitle"),
          svg: process.env.BASE_URL + "media/svg/icons/Code/Terminal.svg"
        },
        {
          title: this.$t("layout.quickactions.enviroment_title"),
          href: "enviroment",
          desc: this.$t("layout.quickactions.enviroment_subtitle"),
          svg: process.env.BASE_URL + "media/svg/icons/Communication/Clipboard-check.svg"
        },
        {
          title: this.$t("layout.quickactions.settings_title"),
          href: "settings",
          desc: this.$t("layout.quickactions.settings_subtitle"),
          svg: process.env.BASE_URL + "media/svg/icons/General/Settings-1.svg"
        }
      ]
    };
  },
  methods: {
    onClickQuickAction(){
        this.$parent.$parent.onClickQuickAction();
    }
  },
  computed: {
    backgroundImage() {
      return process.env.BASE_URL + "media/misc/bg-1.jpg";
    }
  }
};
</script>
