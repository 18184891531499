<template>
    <div>
        <b-modal id="modal-about-application"  scrollable hide-footer :title="$t('layout.footer.about.title')" body-class="p-0">
            <div class="py-10 text-center">
                <inline-svg src="/media/logos/pixio.svg" width="180"/>
            </div>
            <div class="bg-light p-10">
                <h3>{{ $t("main.app_name") }}</h3>
                <span>{{ $t("title.version") }}: </span>
                <span class="text-bold">0.9</span>
                
                <div class="d-flex align-items-center flex-wrap mt-10">
                    <!--begin: Item-->
                    <div class="d-flex align-items-center flex-lg-fill mr-5 my-1 ">
                        <span class="mr-4 ">
                            <span class="svg-icon svg-icon-2x svg-icon-primary">
                                <inline-svg src="/media/svg/icons/Communication/Mail-opened.svg" />
                            </span>
                        </span>
                        <div class="d-flex flex-column text-dark-75">
                            <span class="font-weight-bolder font-size-sm">{{ $t("label.email") }}</span>
                            <span class="font-weight-bolder font-size-h5">
                            <span class="text-dark-50 font-weight-bold"></span>{{ $t("main.company_email") }}</span>
                        </div>
                    </div>
                    <!--end: Item-->
                    <!--begin: Item-->
                    <div class="d-flex align-items-center flex-lg-fill mr-5 my-1">
                        <span class="mr-4">
                            <span class="svg-icon svg-icon-2x svg-icon-primary">
                                <inline-svg src="/media/svg/icons/Communication/Call.svg" />
                            </span>
                        </span>
                        <div class="d-flex flex-column text-dark-75">
                            <span class="font-weight-bolder font-size-sm">{{ $t("label.support") }}</span>
                            <span class="font-weight-bolder font-size-h5">
                            <span class="text-dark-50 font-weight-bold"></span>{{ $t("main.company_phone") }}</span>
                        </div>
                    </div>
                    <!--end: Item-->
                </div>
                <div class="mt-10">
                    © 2009 - {{ currentYear }} <br />
                    {{ $t("layout.footer.about.copyright") }}
                </div>
            </div>
            <div class="py-5 px-10">
                <ul class="list-unstyled">
                    <li><a href="#">{{ $t("layout.footer.about.gdpr_link") }}</a></li>
                    <li><a href="https://www.pixio.cz/kontakt" target="_blank">{{ $t("layout.footer.about.contact_link") }}</a></li>
                </ul>
            </div>
        </b-modal>
    </div>
</template>
<script>

export default {
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  }
};
</script>