<template>
  <!-- begin:: Header Topbar -->
  <div class="topbar align-items-center">

    <!--begin: Search -->
    <b-dropdown
      size="sm"
      id="kt_fast_action_toggle"
      variant="link"
      toggle-class="topbar-item text-decoration-none"
      no-caret
      right
      no-flip
    >
      <template v-slot:button-content>
        <div class="btn btn-icon btn-clean btn-l btn-dropdown mr-2">
          <span class="svg-icon svg-icon-xl svg-icon-primary">
            <inline-svg src="/media/svg/icons/Navigation/Plus.svg" />
          </span>
        </div>
      </template>
      <b-dropdown-text class="py-4">
        <b-dropdown-item>
         <span class="svg-icon svg-icon-xl svg-icon-primary ">
            <inline-svg src="/media/svg/icons/Communication/Clipboard-list.svg" />
          </span>
          Přidat interní úkol
        </b-dropdown-item>
      </b-dropdown-text>
    </b-dropdown>
    <!--end: Search -->

    <!--begin: Search -->
    <b-dropdown
      size="sm"
      id="kt_quick_search_toggle"
      variant="link"
      toggle-class="topbar-item text-decoration-none"
      no-caret
      right
      no-flip
    >
      <template v-slot:button-content>
        <div class="btn btn-icon btn-clean btn-l btn-dropdown mr-2">
          <span class="svg-icon svg-icon-xl svg-icon-primary">
            <inline-svg src="/media/svg/icons/General/Search.svg" />
          </span>
        </div>
      </template>
      <b-dropdown-text tag="div" class="min-w-md-350px">
        <KTSearchDefault></KTSearchDefault>
      </b-dropdown-text>
    </b-dropdown>
    <!--end: Search -->

    <!--begin: Calendar -->  
    <router-link :to="{ name: 'calendar'}" v-slot="{ href, navigate}" >
      <a :href="href" class="menu-link" @click="navigate">
          <div class="btn btn-icon btn-clean btn-l btn-dropdown mr-2" v-b-tooltip.bottom='$t("layout.header.calendar")'>
            <span class="svg-icon svg-icon-xl svg-icon-primary">
              <inline-svg src="/media/svg/icons/Layout/Layout-top-panel-4.svg" />
            </span>
          </div>
      </a>
    </router-link>
    <!--end: Calendar -->  

     <!--begin: Calendar -->  
    <router-link :to="{ name: 'tasks'}" v-slot="{ href, navigate}" >
      <a :href="href" class="menu-link" @click="navigate">
          <div class="btn btn-icon btn-clean btn-l btn-dropdown mr-2" v-b-tooltip.bottom='$t("layout.header.tasks")'>
            <span class="svg-icon svg-icon-xl svg-icon-primary">
              <inline-svg src="/media/svg/icons/Communication/Clipboard-list.svg" />
            </span>
          </div>
      </a>
    </router-link>
    <!--end: Calendar -->  

    <!--begin: Notifications -->
    <b-dropdown
      size="sm"
      variant="link"
      toggle-class="topbar-item text-decoration-none"
      no-caret
      right
      no-flip
    >
      <template v-slot:button-content>
        <div class="btn btn-icon btn-clean btn-dropdown btn-l mr-2" v-b-tooltip.bottom='$t("layout.header.notifications")' >
          <span class="svg-icon svg-icon-xl svg-icon-primary">
            <inline-svg src="/media/svg/icons/General/Notifications1.svg" />
          </span>
          <span class="pulse-ring"></span>
        </div>
      </template>
      <b-dropdown-text tag="div" class="min-w-md-350px">
        <form>
          <KTDropdownNotification></KTDropdownNotification>
        </form>
      </b-dropdown-text>
    </b-dropdown>
    <!--end: Notifications -->

    <!--begin: Quick Actions -->
    <b-dropdown
      ref="ddown"
      size="sm"
      variant="link"
      toggle-class="topbar-item text-decoration-none"
      no-caret
      right
      block
      no-flip
    >
      <template v-slot:button-content>
        <div class="btn btn-icon btn-clean btn-dropdown btn-l mr-2" v-b-tooltip.bottom='$t("layout.header.management")'>
          <span class="svg-icon svg-icon-xl svg-icon-primary">
            <inline-svg src="/media/svg/icons/General/Settings-2.svg" />
          </span>
        </div>
      </template>
      <b-dropdown-text tag="div" class="min-w-600px">
        <KTDropdownQuickAction></KTDropdownQuickAction>
      </b-dropdown-text>
    </b-dropdown>
    <!--end: Quick Actions -->

    <!--begin: User Bar -->
    <KTQuickUser></KTQuickUser>
    <!--end: User Bar -->
  </div>
  <!-- end:: Header Topbar -->
</template>

<style lang="scss">
.topbar {
  .dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
import KTSearchDefault from "@/view/layout/extras/dropdown/SearchDefault.vue";
import KTDropdownNotification from "@/view/layout/extras/dropdown/DropdownNotification.vue";
import KTDropdownQuickAction from "@/view/layout/extras/dropdown/DropdownQuickAction.vue";
import KTQuickUser from "@/view/layout/extras/offcanvas/QuickUser.vue";

export default {
  name: "KTTopbar",
  data() {
    return {
      
    };
  },
  components: {
    KTSearchDefault,
    KTDropdownNotification,
    KTDropdownQuickAction,
    KTQuickUser,
  },
  methods: {
    onClickQuickAction() {
      this.$refs.ddown.hide(true)
    }
  },
  computed: {
    
  }
};
</script>
