var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"menu-nav"},_vm._l((_vm.menuGroups),function(menuItems,index){return _c('div',{key:index},[_c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t(menuItems.group_name)))]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})]),_vm._l((menuItems.items),function(menuItem,index2){return _c('router-link',{key:index2,attrs:{"to":menuItem.route},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active',
            {
              'menu-item-open': _vm.hasActiveChildren(menuItem.route),
            }
          ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[(menuItem.childrens)?_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":href}},[_c('i',{staticClass:"menu-icon",class:menuItem.icon}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t(menuItem.name)))]),_c('i',{staticClass:"menu-arrow"})]):_c('a',{staticClass:"menu-link ",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon",class:menuItem.icon}),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t(menuItem.name)))])]),(menuItem.childrens)?_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"})])]),_vm._l((menuItem.childrens),function(menuSubItem,index3){return _c('router-link',{key:index3,attrs:{"to":menuSubItem.route},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var href = ref.href;
          var navigate = ref.navigate;
          var isActive = ref.isActive;
          var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active'
                  ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.$t(menuSubItem.name)))])])])]}}],null,true)})})],2)]):_vm._e()])]}}],null,true)})})],2)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }