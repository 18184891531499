<template>
  <ul class="menu-nav">

    <div 
      v-for="(menuItems, index) in menuGroups"
      :key="index">
      
        <li class="menu-section">
            <h4 class="menu-text">{{ $t(menuItems.group_name) }}</h4>
            <i class="menu-icon flaticon-more-v2"></i>
        </li>

        <router-link
          v-for="(menuItem, index2) in menuItems.items"
          :key="index2"
          :to = menuItem.route
          v-slot="{ href, navigate, isActive, isExactActive }"
         >
          <li 
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
              {
                'menu-item-open': hasActiveChildren(menuItem.route),
              }
            ]"
            >
            
            <a :href="href" class="menu-link menu-toggle" v-if="menuItem.childrens"> 
              <i class="menu-icon" :class="menuItem.icon"></i>
              <span class="menu-text">{{ $t(menuItem.name) }}</span>
              <i class="menu-arrow"></i>
            </a>

            <a :href="href" class="menu-link " @click="navigate" v-else>
              <i class="menu-icon" :class="menuItem.icon"></i>
              <span class="menu-text">{{ $t(menuItem.name) }}</span>
            </a>

            <div class="menu-submenu" v-if="menuItem.childrens">
              <span class="menu-arrow"></span>
              <ul class="menu-subnav">
                <li aria-haspopup="true" class="menu-item menu-item-parent">
                  <span class="menu-link">
                    <span class="menu-text"></span>
                  </span>
                </li>

                <router-link
                  v-for="(menuSubItem, index3) in menuItem.childrens"
                  :key="index3"
                  :to="menuSubItem.route"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-bullet menu-bullet-dot">
                        <span></span>
                      </i>
                      <span class="menu-text">{{ $t(menuSubItem.name) }}</span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </div>

          </li>
         </router-link>
    </div>
  </ul>
</template>

<script>
import ApiService from "@/core/services/api.service";
export default {
  name: "KTMenu",
  data() {
    return {
      menuGroups: null
    };
  },
  beforeMount() {
    this.getMenu();
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    getMenu()
    {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet(
        "/menu/sidebar")
        .then((response) => {
          this.menuGroups = response.data;
        });
    }
  }
};
</script>
