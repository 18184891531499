<template>
  <div>
    <!-- begin:: Footer -->
    <div class="footer bg-white py-0 d-flex flex-lg-column" id="kt_footer">
      <div
        class="d-flex flex-column flex-md-row align-items-center justify-content-between"
        v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
      >
          <!--begin::Copyright-->
          <div class="text-muted">
            © 2009 - {{ currentYear }} {{ $t("main.company_name") }}
          </div>
          <!--end::Copyright-->
          <!--begin::Nav-->
          <div class="nav nav-dark">
            <a href="#" class="nav-link pl-0 pr-5" v-b-modal.modal-about-application> {{ $t("layout.footer.about.title") }}</a>
          </div>
          <!--end::Nav-->
      </div>
    </div>
    <!-- end:: Footer -->
    <AboutApplicationModal></AboutApplicationModal>
</div>
</template>

<script>
import { mapGetters } from "vuex";
import AboutApplicationModal from "@/view/layout/footer/AboutApplicationModal";

export default {
  name: "KTFooter",
  components: {
    AboutApplicationModal
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    /**
     * Check if footer container is fluid
     */
    widthFluid() {
      return this.layoutConfig("footer.width") === "fluid";
    }
  }
};
</script>
